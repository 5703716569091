import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyD-3m5vsAWHyqOLQWzolb4Bf2JlG5-bvEk',
  authDomain: 'heybotstats.firebaseapp.com',
  projectId: 'heybotstats',
  storageBucket: 'heybotstats.appspot.com',
  messagingSenderId: '370675789310',
  appId: '1:370675789310:web:35935b027cff84dfe364af',
};

const firestoreApp = firebase.initializeApp(firebaseConfig, 'conversationIvr');

const db = firestoreApp.firestore();

// export utils/refs
export default db;
