<template>
  <div class="grid-x grid-margin-x">
    <div class="cell small-12 medium-12 large-12 download">
      <json-excel
        class="button outline accent"
        :fetch="allConversation"
        :fields="jsonFields"
        :encoding="jsonMeta"
        type="csv"
        v-show="!loadingDownload"
        :name="'Reporte de bot.csv'"
        :before-generate="startDownload"
        :before-finish="finishDownload"
      >
        <download-icon /> {{ $t('notification.download-excel') }}
      </json-excel>
      <button
        class="button outline accent"
        :disabled="true"
        v-if="loadingDownload"
      >
        <download-icon /> {{ $t('notification.download-excel') }}
      </button>
    </div>
    <div class="cell small-12 medium-6 large-4" v-for="(step, i) in conversation" :key="i">
      <data-card
        v-bind:card-id="step.name"
        v-bind:theme="'blue'"
        v-bind:name="step.name"
        v-bind:value="step.value">
        <clock-outline-icon/>
      </data-card>
    </div>
    <div class="cell small-12 medium-12">
      <data-chart
        ref="conversationSteps"
        v-bind:name="'app.invocations'"
        v-bind:chart-id="'conversationSteps'"
        v-bind:chart-data="{ categories: labels, series }"
        v-bind:type="'line'"
        v-on:change="chartRangeChanged"/>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-named-as-default
import moment from 'moment-timezone';
import db from '@/utils/firebaseFunctions';
import jsonExcel from 'vue-json-excel';
import { mapGetters } from 'vuex';

const COLLECTION = 'PROVIDENT';

export default {
  props: {
    filters: {
      required: true,
    },
  },
  components: {
    jsonExcel,
    DataCard: () => import('@/components/DataCard.vue'),
    DataChart: () => import('@/components/DataChart.vue'),
  },
  data() {
    return {
      campaignId: null,
      conversation: [{ name: 'conversationIvr' }],
      labels: [],
      series: [],
      loadingDownload: false,
      chartOptions: {},
      jsonMeta: [
        [{
          key: 'charset',
          value: 'utf-8',
        }],
      ],
    };
  },
  computed: {
    ...mapGetters({
      company: 'shared/getCurrentCompany',
    }),
    jsonFields() {
      return {
        Step: 'id_step',
        Conversation: 'id_conversation',
        createdAt: 'dt_created',
      };
    },
    queryConversations() {
      const start = moment(this.filters.startDate);
      const end = moment(this.filters.endDate).endOf('day');
      return db.collection(COLLECTION)
        .where('id_campaign', '==', this.campaignId)
        .where('dt_created', '>=', start.toDate())
        .where('dt_created', '<=', end.toDate());
    },
  },
  watch: {
    'filters.campaignId': function setCampaign(newValue) {
      if (newValue !== null) {
        this.campaignId = newValue;
        this.getConversationSteps();
      }
    },
  },
  mounted() {
    if (this.filters.campaignId !== null) {
      this.campaignId = this.filters.campaignId;
      this.getConversationSteps();
    }
  },
  methods: {
    async getConversationSteps() {
      this.$store.commit('dashboard/enableLoader', 'conversationIvr');
      const snap = await this.queryConversations.get();
      if (!snap.empty) {
        this.mapSnap(snap);
      } else {
        this.conversation = [];
      }
    },
    mapSnap(snap) {
      const arrayAux = [];
      snap.docs.forEach((doc) => {
        arrayAux.push(doc.data());
      });

      const result = arrayAux.reduce((map, step) => {
        if (map.get(step.id_step)) {
          const auxCont = map.get(step.id_step) + 1;
          return map.set(step.id_step, auxCont);
        }
        map.set(step.id_step, 1);
        return map;
      }, new Map());

      this.createGraphOfInvocaciones(result);
      const stepsArray = Array.from(result, ([name, value]) => ({ name, value }));
      this.conversation = stepsArray.sort((a, b) => this.orderByName(a, b));
    },
    createGraphOfInvocaciones(map) {
      const label = [];
      const serie = [];
      map.forEach((value, key) => {
        label.push(key);
        serie.push(value);
      });
      this.labels = label;
      this.series = [{ data: serie, name: 'Steps' }];
    },
    orderByName(a, b) {
      const one = a.name.match(/\d\w\d?/g).toString();
      const two = b.name.match(/\d\w\d?/g).toString();
      return one.localeCompare(two);
    },
    chartRangeChanged() {
    },
    async allConversation() {
      const snap = await this.queryConversations
        .orderBy('dt_created')
        .orderBy('id_conversation')
        .get();
      return snap.docs.map(doc => ({
        ...doc.data(),
        dt_created: moment(doc.data().dt_created.toDate()).format(),
      }));
    },
    startDownload() {
      this.loadingDownload = true;
    },
    finishDownload() {
      this.loadingDownload = false;
    },
  },
};

</script>

<style scoped lang="scss">

  .empty-rows {
    background-color: white;
  }

.download {
  margin-bottom: 10px;
}

</style>
